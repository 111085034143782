<template>
  <v-row class="my-2 mx-1">
    <v-col
      cols="11"
      style="border:1px solid #dbdade; border-radius:5px;"
    >
      <v-row>
        <v-col cols="3">
          <v-row>
            <v-col cols="10">
              <v-switch
                v-model="data.range_date"
                :label="$t('lbl.rangeDate')"
                hide-details
              ></v-switch>
            </v-col>
            <v-col
              v-if="data.range_date"
              cols="2"
            >
              <v-tooltip
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-8 mr-2"
                    fab
                    dark
                    x-small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="addDateTarifa()"
                  >
                    <v-icon small>
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="data.range_date"
          cols="9"
        >
          <v-row class="pt-2">
            <DataCancelacionFreeDates
              v-for="(date, index) in data.dates"
              :key="index"
              :pos="index"
              :date="date"
              @deleteDate="deleteDate(pos)"
            />
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-switch
            v-model="data.antes_de"
            :label="$t('lbl.antesDe')"
            hide-details
            class="pt-0 mt-0"
          ></v-switch>
        </v-col>
        <v-col
          cols="2"
        >
          <v-select
            v-model="data.param"
            :items="parameters"
            class="my-input mr-2"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="slug"
          ></v-select>
        </v-col>
        <v-col
          cols="1"
        >
          <v-text-field
            v-model="data.from"
            type="number"
            hide-details
            :label="data.param === 'days' ? 'días': 'hrs'"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          v-if="!data.antes_de"
          cols="1"
        >
          <v-text-field
            v-model="data.to"
            type="number"
            hide-details
            :label="data.param === 'days' ? 'días': 'hrs'"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-switch
            v-model="data.all_nights"
            :label="$t('lbl.allNigths')"
            hide-details
            class="pt-0 mt-0"
          ></v-switch>
        </v-col>
        <v-col
          v-if="!data.all_nights"
          cols="1"
        >
          <v-text-field
            v-model="data.cant_nigths"
            type="number"
            hide-details
            :label="$t('lbl.cantNights')"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-switch
            v-model="data.all_rooms"
            :label="$t('lbl.allRooms')"
            hide-details
            class="pt-0 mt-0"
          ></v-switch>
        </v-col>
        <v-col
          v-if="!data.all_rooms"
          cols="1"
        >
          <v-text-field
            v-model="data.cant_rooms"
            type="number"
            hide-details
            :label="$t('lbl.cantRooms')"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col
          cols="2"
        >
          <v-switch
            v-model="data.penalidad_in_pc"
            :label="`${$t('lbl.penalidad')} %`"
            hide-details
            class="pt-0 mt-0"
          ></v-switch>
        </v-col>
        <v-col
          cols="2"
        >
          <v-text-field
            v-model="data.penalidad_valor"
            type="number"
            :prefix="!data.penalidad_in_pc ? '$' : ''"
            :suffix="data.penalidad_in_pc ? '%' : ''"
            hide-details
            :label="$t('lbl.valor')"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1">
      <v-row
        class="my-0"
        align="center"
        justify="center"
        style="height:100%;"
      >
        <v-col class="px-4">
          <v-tooltip
            v-if="tarifa.cancele_free_data.length > 1"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                x-small
                color="error"
                @click="$emit('deleteTarifa', pos)"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.delete') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiPlus } from '@mdi/js'
import DataCancelacionFreeDates from './DataCancelacionFreeDates.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DataCancelacionFreeDates,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    tarifa: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    data: {
      type: Object,
    },
    pos: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiDeleteOutline,
      },
      parameters: [],
    }
  },
  created() {
    this.parameters.push({
      name: 'días',
      slug: 'days',
    })
    this.parameters.push({
      name: 'hrs',
      slug: 'hrs',
    })
  },
  methods: {
    ...mapMutations(['deleteObservationContrateHotel']),
    addDateTarifa() {
      this.data.dates.push({
        from: null,
        to: null,
      })
    },
    deleteDate(pos) {
      this.data.dates.splice(pos, 1)
    },
  },
}
</script>
