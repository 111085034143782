<template>
  <v-navigation-drawer
    :value="isSidebarSendActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="650"
    app
    @input="val => $emit('update:is-sidebar-send-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('menu.conditionGenerals') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-send-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col
              v-for="(category, index) in categories"
              :key="index"
              cols="12"
              md="12"
              style="border:1px solid #dbdade; border-radius:5px;"
              class="my-4"
            >
              {{ category.name }}
              <v-divider class="mb-2"></v-divider>
              <v-row
                v-for="(condition, iC) in category.conditions"
                :key="iC"
              >
                <v-col cols="11">
                  <v-checkbox
                    v-model="tarifa.conditions"
                    class="my-0"
                    :value="condition.id"
                  >
                    <template v-slot:label>
                      <div style="font-size: 13px">
                        {{ condition.name }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="1">
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          style="cursor: pointer;"
                          class="ml-2"
                        >
                          mdi-information-outline
                        </v-icon>
                      </span>
                    </template>
                    <span>
                      <v-card
                        class="mx-auto"
                        max-width="500"
                      >
                        <v-card-text class="primary">
                          <p
                            style="color: #FFF"
                            v-html="`<i>${condition.description}</i>`"
                          ></p>
                        </v-card-text>
                      </v-card>
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!--<v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-send-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :loading="loading"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>-->
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
  model: {
    prop: 'isSidebarSendActive',
    event: 'update:is-sidebar-send-active',
  },
  props: {
    isSidebarSendActive: {
      type: Boolean,
      required: true,
    },
    tarifa: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      icons: {
        mdiClose,
      },
    }
  },
  computed: {
    ...mapState({
      loadingBtn: state => state.app.loadingBtn,
    }),
  },

  methods: {
    ...mapMutations(['setCheckAllCobros', 'updateItemCheckCobros']),
  },
}
</script>
