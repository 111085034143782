<template>
  <fragment>
    <v-col
      cols="5"
      class="mt-2"
      style="border:1px solid #dbdade; border-radius:5px;"
    >
      <v-row>
        <v-col cols="6">
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromDateFormatted"
                :label="$t('lbl.from')"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                style="border-radius: 5px 0px 0px 5px"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date.from"
              no-title
              :min="minDate.toISOString().substr(0, 10)"
              locale="es"
              :show-current="false"
              @input="
                menuFrom = false
                activeToDate()
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="6"
        >
          <v-menu
            v-model="menuTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToDateFormatted"
                :disabled="!date.from"
                :label="$t('lbl.to')"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                style="border-radius: 0px 5px 5px 0px"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date.to"
              no-title
              locale="es"
              :show-current="false"
              :min="
                date.from
                  ? $moment(date.from)
                    .add(1, 'day')
                    .toDate()
                    .toISOString()
                    .substr(0, 10)
                  : minDate
              "
              @input="menuTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      class="mt-2"
      cols="1"
    >
      <v-row
        class="my-0"
        align="center"
        justify="center"
        style="height:100%;"
      >
        <v-col class="px-4">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                x-small
                color="error"
                @click="$emit('deleteDate', {pos : pos})"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.delete') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </fragment>
</template>
<script>
import { mapMutations } from 'vuex'
import { mdiDeleteOutline } from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    date: {
      type: Object,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
      },
      minDate: new Date(),
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    computedFromDateFormatted() {
      if (this.date.from) {
        return this.date.from ? this.$moment(this.date.from).format('D-MMM-YY') : ''
      }

      return ''
    },
    computedToDateFormatted() {
      if (this.date.to) {
        return this.date.to ? this.$moment(this.date.to).format('D-MMM-YY') : ''
      }

      return ''
    },
  },
  methods: {
    ...mapMutations(['deleteObservationContrateHotel']),
    activeToDate() {
      setTimeout(() => {
        this.menuTo = true

        this.date.to = this.$moment(this.date.from)
          .add('days', 1)
          .format('YYYY-MM-DD')
      }, 100)
    },
  },
}
</script>
