var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-col',{staticClass:"mt-2",staticStyle:{"border":"1px solid #dbdade","border-radius":"5px"},attrs:{"cols":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"border-radius":"5px 0px 0px 5px"},attrs:{"label":_vm.$t('lbl.from'),"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedFromDateFormatted),callback:function ($$v) {_vm.computedFromDateFormatted=$$v},expression:"computedFromDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.minDate.toISOString().substr(0, 10),"locale":"es","show-current":false},on:{"input":function($event){_vm.menuFrom = false
              _vm.activeToDate()}},model:{value:(_vm.date.from),callback:function ($$v) {_vm.$set(_vm.date, "from", $$v)},expression:"date.from"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"disabled":!_vm.date.from,"label":_vm.$t('lbl.to'),"persistent-hint":"","readonly":"","outlined":"","dense":"","clearable":""},model:{value:(_vm.computedToDateFormatted),callback:function ($$v) {_vm.computedToDateFormatted=$$v},expression:"computedToDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es","show-current":false,"min":_vm.date.from
                ? _vm.$moment(_vm.date.from)
                  .add(1, 'day')
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
                : _vm.minDate},on:{"input":function($event){_vm.menuTo = false}},model:{value:(_vm.date.to),callback:function ($$v) {_vm.$set(_vm.date, "to", $$v)},expression:"date.to"}})],1)],1)],1)],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"1"}},[_c('v-row',{staticClass:"my-0",staticStyle:{"height":"100%"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"px-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.$emit('deleteDate', {pos : _vm.pos})}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }